<template>
  <div :style="getAppStyle()+'padding-right: 10% !important; height: 100%; width: 100%;'">
	<div :style="'display: block; float: left; padding-left: 10% !important; height: 100%; width: calc(90vH);'">
    <div style='margin-left: 20pt; font-size: 14pt; margin-top: 10pt; color: rgba(100,200,255,0.9); text-shadow: 4px 4px 4px rgba(100,100,255,0.5);'>SUDOKU (solver)</div>
    <div style='margin-left: 20pt;margin-bottom: 10pt; font-size: 9pt;'>(solving a Sudoku with the in-built optimizer)</div>
    <div style='float: left;'>
	    <div style='border: 3px outset #cce; margin-left: 10pt; display: block; width: 270pt;'>
		    <div v-for="(r,ri) in columns" :key="ri" :style="getAppStyle()" class='noLineBreak'>
		    	 <span v-for="(c,ci) in columns" :key="ri+'.'+ci" :id="ri+'.'+ci" :style="getAppStyle()+getCellStyle(r,c)" class='field' 
		    	        @click="markNumber($event, number[r][c])"
		    	        @contextmenu.prevent="ctxMenu($event, r, c)">
		    	    <span v-if="number[r][c]" :style="'font-weight: bold; color:'+ (isDarkMode()?'#ccf;':'#66d;')">
		    	    {{number[r][c]}}
		    	    </span>
		    	    <span v-else-if="!solved[r][c]" style='font-weight: bold; color: #f44;'>
		    	    {{solved[r][c]}}
		    	    </span>
		    	    <span v-else>
		    	    {{solved[r][c]}}
		    	    </span>
		    	 	
		         </span>
		         <br/>
		     </div>
		     
		  </div>
		  <div style='margin-left: 20pt;margin-bottom: 0pt; font-size: 9pt;'>(left-click on a field with number to see possible fields to set this number)</div>
		  <div style='margin-left: 20pt;margin-bottom: 10pt; font-size: 9pt;'>(right-click on a field to set the number)</div>
	  </div>
	  <div style='float: right; width:20%;'>
		  <button :style="getAppStyle()" :title="'solve the given Sudoku'" type='button' class='button' @click='solve'>SOLVE</button><br/>
		  <br/>
		  
		  <button :style="getAppStyle()" :title="'create a sample Sudoku (60% filled)'" type='button' class='button' @click='create(60)'>Create XS</button><br/>
		  <button :style="getAppStyle()" :title="'create a sample Sudoku (50% filled)'" type='button' class='button' @click='create(50)'>Create S</button><br/>
		  <button :style="getAppStyle()" :title="'create a sample Sudoku (25% filled)'" type='button' class='button' @click='create(40)'>Create M</button><br/>
		  <button :style="getAppStyle()" :title="'create a sample Sudoku (25% filled)'" type='button' class='button' @click='create(32)'>Create L</button><br/>
		  <button :style="getAppStyle()" :title="'create a sample Sudoku (25% filled)'" type='button' class='button' @click='create(28)'>Create XL</button><br/>
		  <button :style="getAppStyle()" :title="'create a sample Sudoku (25% filled)'" type='button' class='button' @click='create(28)'>Create XXL</button><br/>
		  <br/>
		  <button :style="getAppStyle()" :title="'Reset the board, clear the solution'" type='button' class='button' @click='reset'>Reset</button><br/>
		  <button :style="getAppStyle()" :title="'clear the board'" type='button' class='button' @click='clear'>Clear</button><br/>
		   <br/>
		   
		   <div v-if="fields.filter(p=>!p.number).length">{{fields.filter(p=>!p.number).length}} fields remaining</div>
		   <div v-else style='font-size: 12pt;color: rgba(100,200,255,0.9);'>CONGRATULATONS</div>
	 	
	  </div>
	  </div>
	  
		  <ContextMenu ref="menu" :offsetY="+10">
	      <template v-if="contextData && contextData.row" slot-scope="{ contextData }">
	      
	        <ContextMenuItem v-if="number[contextData.row][contextData.col]" @clicked="$refs.menu.close(); setNumber(contextData.row,contextData.col,'-')">
	        	clear field
	        </ContextMenuItem>
	        <ContextMenuSep v-if="mark && number[contextData.row][contextData.col]"/>
	        <ContextMenuItem v-if="number[contextData.row][contextData.col]" @clicked="$refs.menu.close(); mark=number[contextData.row][contextData.col]">
	        	mark all {{number[contextData.row][contextData.col]}}
	        </ContextMenuItem>
	        <ContextMenuItem v-if="mark" @clicked="$refs.menu.close(); mark=0">
	        	remove mark for {{mark}}
	        </ContextMenuItem>
	        <ContextMenuSep v-if="mark || number[contextData.row][contextData.col]"/>
	        
	        <ContextMenuItem v-for="(n,ni) in filter( contextData.row, contextData.col, columns)" :key="'c_'+ni"  @clicked="$refs.menu.close(); setNumber(contextData.row,contextData.col,n)">
	        {{n}}
	        </ContextMenuItem>
	        
	        
	        </template>
	      </ContextMenu>
     
	  <NumberPopUp :r=r :c=c :fields=fields @number="setNumber"></NumberPopUp>
	  <ProgressBar v-if="showProgressBar" :generalInfo=pbInfo :action=pbAction @action=action></ProgressBar>

  </div>
</template>
<script>
// import axios from 'axios';
import { getAppStyle, setDarkMode, initAppMode, isDarkMode } from '@/AppStyle.js';
import { myTimer, receiveToken, HTTP, showError, myName, serviceAPI, sudokuAPI} from '../variables.js';
import { getCopyright } from '../utils.js';
import ProgressBar from '@/components/ProgressBar';
import NumberPopUp from '@/components/misc/NumberSelect';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import WIDGETS from '@/components/desktopWidgets/WorkbenchWidgets';
import JQuery from "jquery";
let $ = JQuery;
export default {
  name: 'about',
  components: { NumberPopUp, ProgressBar, ContextMenu,ContextMenuItem, ContextMenuSep },
  data() {
      return {
    	columns: [1,2,3,4,5,6,7,8,9],
    	fields: [ { row: 0, col: 0, section: 0, number: 0}], 
    	r:0,
    	c:0,
    	degree: 50,
    	number: [],
    	solved: [],
    	showProgressBar: false,
    	pbInfo: "",
    	pbAction: "",
    	mark: 0,
    	markPossible: 0,
    	numberSave: [],
    	getCopyright, getAppStyle, isDarkMode
      }
  },
  methods:{
	  
	  getCellStyle(r,c)
	  {
		  let n = this.number[r][c];
		  
		  let style = "";
		  if (this.deadlock( r, c) )
		  {
			  style += "background-color: #f88;";
		  }
		  else
		  if ( n === this.mark || this.markField(r,c) )
		  {
			  style += "background-color: #888;";
		  }
		  if ( (r % 3) === 0)
		  {
			  style += "border-bottom: 3px solid #aae !important;"
		  }
		  if ( (c % 3) === 0)
		  {
			  style += "border-right: 3px solid #aae !important;"
		  }
		  return style;
	  },
	  filter( row, col, numbers)
	  {
			let valid = [];
			for ( let n in numbers )
			{
				if ( this.ok(row, col, numbers[n]))
				{
					valid.push( numbers[n]);
				}
			}
			return valid;
	  },
	  isEmpty(x)
	  {
		  return x.number === 0 || x.number === null;
	  },
	  ctxMenu(event, row, col) 
      { 
	  	
	  	if (event) event.preventDefault();
	  	
	  	this.$refs.menu.open( event, {row: row, col: col});
        
      },
      markNumber( evt,  n )
      {
    	 
    	  if ( n !== '-')
    	  {
	    	  if ( this.markPossible === n)
	    	  {
	    		  this.markPossible = 0;
	    	  }
	    	  else
	    	  {
	    	  	this.markPossible = n;
	    	  }
    	  }
    	  else
    	  {
    		  this.markPossible = 0;
    	  }
      },
      markField( r, c)
      {
    	if ( this.markPossible )
    	{
    		return !this.ok( r, c, this.markPossible );
    	}
    	return false;
      },
	  setNumber(r, c, n)
	  {
    	  this.$refs.menu.close();
    	  if ( n === '-')
	      {
			  this.number[r][c] = null;
	      }
		  else
		  {	
		 	 this.number[r][c] = n;
		  }
		  this.fields.find( p=>(p.row===r && p.col===c)).number = n;
		  this.$set(this, "number", [...this.number]);
	  },
  	  selectNumber(event, id, r,c) {
		  this.r = r;
		  this.c = c;
		  this.$vm2.open('modal-infoPU')
  	  },
  	  action( what, myId, api)
      {
    	this[what](myId, api)
      },
      rowOk(row, number) 
    	{
    		
    		return !this.fields.filter(p=>p.row==row).find(n=>n.number===number);
    	},
    	colOk(col, number) 
    	{
    		
    		return !this.fields.filter(p=>p.col==col).find(n=>n.number===number);
    	},
    	sectionOk(row, col, number) 
    	{	
    		
    		let section = 1+(Math.floor( (col-1)/3))+3*(Math.floor( (row-1)/3));
    		
    		return !this.fields.filter(p=>p.section===section).find(n=>n.number===number);
    	},
    	ok( row, col, number )
    	{
    		if ( number === '-')
    		{
    			return true;
    		}
    		return this.rowOk( row, number) && this.colOk( col, number) && this.sectionOk( row, col, number);
    	},
  	  reset()
  	  {
  		this.solved = [];
  		for ( let r in this.columns )
  		{
  			this.solved[this.columns[r]] = [];
  			for ( let c in this.columns )
  			{
  				this.solved[this.columns[r]][this.columns[c]] = null;
  			}
  		 }
  		 this.clearZeros(this.numberSave)
  		 this.$set(this, "number", [...this.numberSave]);
  		 
  	  },
  	  clear()
	  {
  		this.number = [];
  		this.fields = [];
  		for ( let r in this.columns )
  		{	
  			this.number[this.columns[r]] = [];
  			this.solved[this.columns[r]] = [];
  			for ( let c in this.columns )
  			{
  				let row = this.columns[r];
  				let col = this.columns[c];
  				this.number[ row][ col] = null;
  				this.solved[ row][ col] = null;
  				let section =  1+(Math.floor( (col-1)/3))+3*(Math.floor( (row-1)/3));
  				let field = { row: row, col: col, section: section, number: 0 };
  				this.fields.push( field);
  			}
  		 }
		 this.$set(this, "number", [...this.number]);
	  },
	  clearZeros( numbers)
	  {
  		for ( let r in this.columns )
  		{	
  			for ( let c in this.columns )
  			{
  				let row = this.columns[r];
  				let col = this.columns[c];
  				if ( !numbers[ row][ col])
  				{
  					numbers[ row][ col] = null;
  					this.fields.find( p=> (p.row===row && p.col===col)).number = 0;
  				}
  				else
  				{
  					this.fields.find( p=> (p.row===row && p.col===col)).number = numbers[ row][ col];
  				}
  			}
  		 }
  		this.$set( this, "fields", [...this.fields]);
	  },
	  solve()
	  {
		  this.pbAction = "solveInternal";
	      this.pbInfo = "starting solver";
	  	  this.showProgressBar = true;
	  },
	  create( degree)
	  {
		  this.degree = degree;
		  this.pbAction = "createInternal";
	      this.pbInfo = "starting building";
	  	  this.showProgressBar = true;
	  },
	  deadlock(row, col)
	  {
		  let check = 0;
			if ( this.isEmpty(this.fields.find( p=>(p.row===row && p.col===col))))
			{
				let possible = 0;
				for ( let n in this.columns )
				{
					if ( this.ok( row, col, this.columns[n]))
					{
						possible++;
					}
				}
				if ( possible===0 )
				{
					check++;
				}
			}

	  		return check;		
	  },
  	  solveInternal(myId, api)
  	  {
  		let that = this;
  		this.numberSave = [...this.number];
  		api.post( sudokuAPI+"/solve/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/150/"+myId, {list: this.number})
        .then( response => 
        { 
        	
        	let tmp = response.data;
        	let numberTMP = [];
        	numberTMP.push([]);
        	for ( let r in tmp )
        	{
        		tmp[r].unshift(0);
        		numberTMP.push(tmp[r]);
        	}
        	that.showProgressBar = false;
        	
	      	that.clearZeros(numberTMP)
	      	
	      	
        	that.$set(that, "solved", numberTMP);
        	
        	
		    }).catch(e => {
		    	console.log(e)
		        showError(that.$toast, "solve()", e);
		    	that.showProgressBar = false;
		    });
  	  },
  	  createInternal(myId, api)
	  {
		  let that = this;
		
		  api.post( sudokuAPI+"/create/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.degree+"/120/"+myId, {list: this.number})
	      .then( response => 
	      { 
	      	
	      	let tmp = response.data;
	      	let numberTMP = [];
	      	numberTMP.push([]);
	      	for ( let r in tmp )
	      	{
	      		tmp[r].unshift(0);
	      		numberTMP.push(tmp[r]);
	      	}
	      	that.showProgressBar = false;
	      
	      	that.clearZeros(numberTMP)
	      	that.$set( that, "number", numberTMP);
	      	
	      	this.numberSave = [...this.number];
	      	this.reset();
	      	//alert( JSON.stringify(that.number))
			    }).catch(e => {
			    	console.log(e)
			        showError(that.$toast, "solve()", e);
			    	that.showProgressBar = false;
			    });
		  }
  },
  created()
  {
	initAppMode();
	this.clear();
	this.$nextTick(function () {
	    try
	    {
	    	this.$refs.menu.open(event, {spot: {}, name: "" });
	    } catch(e) { /* */ }
	  	this.$refs.menu.close();
		});
	

  },
  
 }
</script>
<style lang="scss" scoped>
  .org-description {
    margin-top: 50px;
  }
.button {
	width: 90pt;
	height: 24pt;
	font-size: 9pt;
}
.field {
    position: relative; 
	width: 30pt; 
	height: 30pt;
	border: .5pt solid grey;
	text-align: center;
	justify-content: center;
	align-items: center;
	float: left;
	margin: 0pt;
	display: flex;
}
.field:hover {
	background-color: #888 !important;
}
.noLineBreak {
    position: relative; 
    float: top;
    display: flex;
    margin: 0pt;
    white-space:nowrap;
}
</style>