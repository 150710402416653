<template>
<vue-modal-2 :darkMode="isDarkMode()" :noFooter=false modalSize="md"
      :headerOptions="{title: 'SELECT NUMBER'}" 
      :footerOptions="{ btn1: 'Close', disableBtn2:true,  btn1OnClick:closeInfoPU}"
      name="modal-infoPU"  @on-close="closeInfoPU">
      <div class='noLineBreak' style='padding-top: 0em; padding-bottom: 1em; display: flex;'>
        <div class='noLineBreak'>
        <div v-for="(n,ni) in numbers" :key="ni" class='field' :style="getAppStyle()" @click="selectNumber(n)">
        	<div v-if="ok(r,c,n)" >
        	{{n}}
        	</div>
	    	<div v-else :disabled="true" class='field' :style="'width: 100%; height: 100%;background-color: #888; color: #d88;'">
        	{{n}}
        	</div>
	    </div>
	    </div>
      </div>
</vue-modal-2>
</template>
<script>
import { getAppStyle, setDarkMode, initAppMode, isDarkMode } from '@/AppStyle.js';
var timers = [];
export default {
  name: "NumberPopUp",
  components : {
    
  },
  props: {
	r: { type: Number, default: 0 },
	c: { type: Number, default: 0 },
	fields: Array
  },
  data() {
      return {
    	 numbers: ['-',1,2,3,4,5,6,7,8,9],
      	 closed: false,
      	 getAppStyle, isDarkMode
      }
  },
  methods: {
    selectNumber(n) {
    	if ( this.ok(this.r, this.c, n) )
    	{ 
    		this.$emit("number", this.r, this.c, n)
    		this.closeInfoPU();
    	}
  	},
  	rowOk(row, number) 
  	{
  		
  		return !this.fields.filter(p=>p.row==row).find(n=>n.number===number);
  	},
  	colOk(col, number) 
  	{
  		
  		return !this.fields.filter(p=>p.col==col).find(n=>n.number===number);
  	},
  	sectionOk(row, col, number) 
  	{	
  		
  		let section = 1+(Math.floor( (col-1)/3))+3*(Math.floor( (row-1)/3));
  		
  		return !this.fields.filter(p=>p.section===section).find(n=>n.number===number);
  	},
  	ok( row, col, number )
  	{
  		if ( number === '-')
  		{
  			return true;
  		}
  		return this.rowOk( row, number) && this.colOk( col, number) && this.sectionOk( row, col, number);
  	},
  	closeInfoPU() { 
  	    timers.push( setTimeout(this.activateAgain, 500));
      	this.$vm2.close('modal-infoPU');
      },
  },
  watch:
  {
  	time: function() { if ( this.time >  0 ) { this.$vm2.open('modal-infoPU'); }}
  }
 }
 </script>
 <style>
 .puInfo {}
 .vm2__header div h4 { font-weight: bold; }
 .noLineBreak {
    position: relative; 
    float: top;
    display: flex;
    margin: 0pt;
    white-space:nowrap;
}
 .field {
    position: relative; 
	width: 30pt; 
	height: 30pt;
	
	border: .5pt solid grey;
	text-align: center;
	justify-content: center;
	align-items: center;
	float: left;
	margin: 0pt;
	display: flex;
}
.field:hover {
	background-color: #888 !important;
}
 </style>